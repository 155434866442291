
<script lang="ts">
    export interface IProps {
        /* title */
        title?: string;
        /* dataObject(s) where data and loading information should come from */
        dataObject?: DataObject | DataObject[];
        /* data override, in case you want to provide custom data or filtered data */
        data?: Array<DataItem>;
        /* (DESKTOP ONLY) determines how many items are shown before you have to click 'show more' to expand the rest */
        showMoreCutoff?: number;
        /* count override (DEPRECATED, a better solution should be found here) */
        count?: number;
        /* (DESKTOP ONLY) sets no data text */
        noDataText?: string;
        /* no collapse on mobile */
        inline?: boolean;
    };
</script>

<script setup lang="ts">
    import { ref, computed, provide, watch } from "vue";
    import { type DataObject, type DataItem } from "o365-data-object";
    import { OCollapse } from "o365-ui-components";
    import ORelatedItemsProvider, { RelatedItemsProvide } from "./RelatedItems.RelatedItemsProvider.vue";
    import { isMobile, isMobileOrTablet } from "o365.GlobalState.ts";
    import { initializeConfigs } from "o365-modules";

    const props = withDefaults(defineProps<IProps>(), { showMoreCutoff: 5 });

    const showRef = ref(false);
    const show = computed({
        get() {
            if (props.inline && isMobile.value) {
                return true;
            } else {
                return showRef.value;
            }
        },

        set(newValue) {
            showRef.value = newValue
        }
    });

    const dataObjects = computed(() => [props.dataObject].flat().filter(e => e));
    const data = computed(() => props.data ?? dataObjects.value.map(e => e.data).reduce((a, b) => a.concat(b)));
    const isLoading = computed(() => dataObjects.value.some(e => e.state.isLoading));

    function onClick(e) {
        if (!e.target.closest(".c-related-checkbox")) {
            show.value = !show.value;
        }
    }

    provide<RelatedItemsProvide>("RelatedItemsProvider", { textOnly: false });
</script>

<template>
    <!-- mobile -->
    <template v-if="isMobile">
        <div class="border shadow-1 mobileRelated" style="border-radius: 0.5rem;" v-bind="$attrs">
            <div class="d-flex align-items-center" style="position: relative;" @click="onClick">
                <div class="flex-grow-1 overflow-hidden">
                    <div class="pt-3 px-3 pb-0 d-flex">
                        <div class="flex-grow-1 d-flex align-items-center gap-1">
                            <div class="fw-medium">{{ title }}</div>
                            <template v-if="isLoading">
                                <div class="spinner-border spinner-border-sm" />
                            </template>
                            <template v-else>
                                <div class="fw-medium">({{ count ?? data.length }})</div>
                            </template>
                        </div>
                    </div>
                    <OCollapse v-model="show" reverse>
                        <div class="px-3">
                            <div class="italic text-muted text-truncate c-textonly-container">
                                <ORelatedItemsProvider textOnly>
                                    <slot name="rows" />
                                    <template v-for="row in data">
                                        <slot name="row" :row="row" />
                                    </template>
                                </ORelatedItemsProvider>
                            </div>
                        </div>
                    </OCollapse>
                    <template v-if="$slots.checkbox">
                        <div class="mt-1 px-3">
                            <slot name="checkbox" />
                        </div>
                    </template>
                    <div class="pb-3" />
                </div>
                <template v-if="$slots.default || $slots.rows || data?.length || $slots.buttons">
                    <div class="flex-shrink-0 px-4" v-if="!props.inline">
                        <div class="flex-shrink-0">
                            <div :style="{ 'transition': 'transform 500ms ease', 'transform': `rotate(${show ? 180 : 0}deg)` }" >
                                <i class="bi bi-chevron-down" />
                            </div>
                        </div>
                    </div>
                </template>
                <!-- <div style="position: absolute; top: 0; right: 0;"> -->
                <!-- </div> -->
            </div>
            <OCollapse v-model="show">
                <slot name="default" />
                <slot name="rows" />
                <template v-for="row in data">
                    <slot name="row" :row="row" />
                </template>
                <slot name="buttons" />
            </OCollapse>
        </div>
    </template>

    <!-- desktop -->
    <template v-else>
        <div v-bind="$attrs">
            <div class="d-flex align-items-baseline gap-2">
                <div class="d-flex align-items-center gap-1">
                    <div class="fw-medium text-muted">{{ title }}</div>
                    <template v-if="isLoading">
                        <div class="spinner-border spinner-border-sm text-muted" />
                    </template>
                </div>
                <slot name="buttons" />
            </div>
            <!-- <div style="margin-top: 0.25rem;"> -->
            <div>
                <slot name="rows" />
                <template v-for="row in data.slice(0, showMoreCutoff)">
                    <slot name="row" :row="row" />
                </template>
                <OCollapse v-model="show">
                    <template v-for="row in data.slice(showMoreCutoff)">
                        <slot name="row" :row="row" />
                    </template>
                </OCollapse>
                <template v-if="!data.length && noDataText">
                    <div class="pb-2"><i>{{ noDataText }}</i></div>
                </template>
            </div>
            <template v-if="data.length > showMoreCutoff">
                <div>
                    <a class="btn btn-sm btn-link" @click="show = !show">{{ show ? $t("Show less") : $t("Show more") }}...</a>
                </div>
            </template>
        </div>
    </template>
</template>

<style scoped>
    .c-textonly-container > *:not(:last-child):after {
        content: ", ";
    }

    .mobileRelated {
        background-color: white;
    }
</style>
